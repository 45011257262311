<template>
  <v-dialog
    v-model="messageDialog"
    :max-width="width"
    content-class="profile-confirm-dialog"
  >
    <v-card id="profile-confirm" class="dialog_content">
      <v-card-title style="background-color: #111111;">{{ title }}</v-card-title>
      <v-card-text style="padding: 20px 24px 44px;"
        ><v-row no-gutters>
          <v-col cols="12" class="message_content" style="padding-top: 6px">
            <div v-html="message"></div>
          </v-col>
          <v-col cols="12 pt-4" class="close_btn_content" style="padding-top: 30px !important">
            <button style="width: 300px;" class="btn btn-primary btn_black" :ripple="false" @click="closeMessage">OK</button>
          </v-col>
        </v-row></v-card-text
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: "ModalMessage",
    data() {
      return {
        messageDialog: false,
        message: "",
        title: "",
        width: "700"
      }
    },
    methods: {
      closeMessage() {
        this.messageDialog = false;
        this.message = "";
        this.title = "";
      },
      open(message, title, width = "550") {
        this.messageDialog = true;
        this.message = message;
        this.title = title;
        this.width = width;
      }
    }
}
</script>
<style scoped>
  #profile-confirm.dialog_content{
    background-color: #111111; 
    border-radius: 8px !important; 
    border: 1px solid #b1b2b3;
  }

  #profile-confirm.dialog_content .v-card__title{
    padding: 12px 24px;
  }

  #profile-confirm.dialog_content .v-card__text{
    margin-top: 0;
    padding: 0 24px 24px;
  }

  #profile-confirm.dialog_content .v-card__text .message_content{
    padding-left: 30px; 
    padding-right: 30px; 
    text-align: center;
  }

  #profile-confirm.dialog_content .v-card__text .close_btn_content{
    text-align: center;
    padding-top: 40px;
  }

  #profile-confirm.dialog_content .v-card__text .close_btn_content .btn_black{
    background-color: #010101;
    border-color: #b1b2b3;
    border-radius: 0;
    padding: 8px 16px;
  }

  #profile-confirm.dialog_content .v-card__text .close_btn_content .btn_black:hover{
    background-color: #292929;
  }

  @media only screen and (max-width: 480px) {
    .btn.btn-primary.btn_black{
      width: 250px !important;
    }
  }
</style>